<template>
  <main class="content-page">
    <section class="submenu">
      <span class="highlight"></span>
      <h1 class="-h6">Lifecycle</h1>
    </section>
    <section class="lifecycle-container">
      <div class="lifecycle-stages-container">
        <LifecycleStages
          :stages="stages"
          :life_cycle_details_ids="map_life_cycle_details_id"
          v-if="map_life_cycle_details_id.length"
        />
      </div>
      <div class="lifecycle-listing-container">
        <LifecycleListing
          :stagesDetails="stagesDetails"
          v-if="stagesDetails.length"
        />
      </div>
    </section>
  </main>
</template>

<script>
import LifecycleStages from "../../../components/navigation/LifecycleStages.vue";
import LifecycleListing from "../../../components/tables/mscope/LifecycleListing.vue";
import { mapGetters } from "vuex";
import { getLifecycleDetails } from "../../../services/requests/get/lifecycle";
export default {
  components: {
    LifecycleStages,
    LifecycleListing,
  },
  data() {
    return {
      stagesDetails: [],
    };
  },
  async created() {
    const res = await getLifecycleDetails();

    if (res.status != 200) return;

    this.stagesDetails = res.data;
    console.log(this.stagesDetails);
  },
  computed: {
    ...mapGetters(["stages"]),
    map_life_cycle_details_id() {
      return this.stagesDetails.map((item) => item.life_cycle_details_id);
    },
  },
};
</script>

<style scoped>
.content-page {
  height: calc(100% - 4px);
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.submenu {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  /* border: 1px solid black; */
}
.highlight {
  display: inline-block;
  height: 50%;
  width: 1px;
  border-radius: 8px;
  border: 2px solid var(--highlight);
  background: var(--highlight);
}
.submenu h1 {
  text-transform: uppercase;
  padding-left: 8px;
}
.actions {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.search-container {
  height: 100%;
  width: 272px;
  text-align: right;
}
.btn-container {
  width: 164px;
  height: 100%;
}
.lifecycle-container {
  height: 95%;
  min-height: 500px;
  width: 100%;
  display: flex;
  --lifecyclestages-container: 12px;
  gap: 8px;
  overflow: auto;
  animation: slide-in-top-menu 600ms ease-out;
}
.lifecycle-stages-container {
  --th-height: 22px;
  width: var(--lifecyclestages-container);
  top: var(--th-height);
  height: calc(100% - var(--th-height));
  position: relative;
}
.lifecycle-listing-container {
  width: calc(100% - var(--lifecyclestages-container));
  height: 100%;
}
@media screen and (max-height: 1023px) {
  .highlight {
    display: none;
  }
}
</style>