<template>
  <main class="lifecycle-stages-container">
    <section class="change-stage-container">
      <div
        class="current-stage"
        ref="currentStageRef"
        :style="`height: calc(100% / ${stages.length});`"
      ></div>
      <div
        v-for="stage in stages"
        :key="stage"
        class="stage-placeholder"
        :ref="`stage${stage.stage_value}`"
        @mouseenter="
          toggleBackground(`stage${stage.stage_value}`, stage.color_code)
        "
        @mouseleave="
          toggleBackground(`stage${stage.stage_value}`, stage.color_code)
        "
        @click="updateCurrentStage(stage.stage_value)"
      ></div>
    </section>
  </main>
</template>

<script>
import { update_current_stage } from "../../services/requests/post/lifecycle";
export default {
  props: ["stages", "life_cycle_details_ids"],
  data() {
    return {
      previousLifecycleDetailsId: this.getCurrentLifecycleDetailsId(
        this.$store.state.currentStage.stage_value
      ),
    };
  },
  methods: {
    toggleBackground(ref, color_code) {
      const elProperty = this.$refs[ref][0].style;
      !elProperty.background
        ? (this.$refs[ref][0].style = `background: ${color_code}`)
        : (this.$refs[ref][0].style = `background: ""`);
    },
    setCurrentStageStyling(ref, index, color_code, stage_value) {
      console.log(ref, index, color_code, stage_value);

      let currentStageStyle = this.$refs[ref].style;
      currentStageStyle.top = `calc(${index} * 100% / ${this.stages.length})`;
      currentStageStyle.background = `${color_code}`;
    },
    async updateCurrentStage(stage_value) {
      const newCurrentLifecycleDetailsId =
        this.getCurrentLifecycleDetailsId(stage_value);

      const data = {
        life_cycle_details_id: this.previousLifecycleDetailsId,
        new_life_cycle_details_id: newCurrentLifecycleDetailsId,
      };

      //AFTER API CALL
      const res = await update_current_stage(data);
      console.log(res);

      if (res != 200) return;

      this.previousLifecycleDetailsId = newCurrentLifecycleDetailsId;
      this.$store.dispatch("unitModule/setUnitCurrentStage", stage_value);

      //updates the current stage on the store
      this.$store.dispatch("setCurrentStage", stage_value);

      //updates the tempalte styling
      const stageInfo = this.getIndexOfCurrentStage();
      this.setCurrentStageStyling(
        "currentStageRef",
        stageInfo.index,
        stageInfo.color_code,
        this.$store.state.currentStage.stage_value
      );
    },

    setSelectedStages(frame_stage_id) {
      //resets the selected stages on StagesMenu.vue
      const newSelectedStages = [];
      newSelectedStages.push(frame_stage_id);
      this.$store.dispatch("selectedStages", newSelectedStages);
    },
    getIndexOfCurrentStage() {
      const stageValuesArr = this.stages.map((stage) => stage.frame_stage_id);
      const stageColorsArr = this.stages.map((stage) => stage.color_code);
      const index = stageValuesArr.indexOf(this.$store.state.currentStageId);

      const stageInfo = { index: index, color_code: stageColorsArr[index] };
      return stageInfo;
    },
    getStageIndex(stage_value) {
      //find the index of the current stage in the stages array and returns it
      return this.$store.state.stages.findIndex(
        (stage) => stage_value == stage.stage_value
      );
    },
    getCurrentLifecycleDetailsId(stage_value) {
      return this.life_cycle_details_ids[this.getStageIndex(stage_value)];
    },
  },
  mounted() {
    const stageInfo = this.getIndexOfCurrentStage();
    console.log(stageInfo);

    this.setSelectedStages(this.$store.state.currentStage.frame_stage_id);

    //NAO ESQUECER DE PASSAR O CURRENT STAGE UNIT COMO PARAMENTRO EM VEZ DO 1 !!!!!!!
    this.setCurrentStageStyling(
      "currentStageRef",
      stageInfo.index,
      stageInfo.color_code,
      this.$store.state.currentStage.stage_value
    );
  },
};
</script>

<style scoped>
.lifecycle-stages-container {
  height: 100%;
  width: 100%;
}
.change-stage-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px -1px;
  position: relative;
  background: var(--gray6);
}
.stage-placeholder {
  height: 100%;
  width: 100%;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms ease;
  opacity: 0.33;
  /* border-radius: 4px; */
}
.current-stage {
  position: absolute;
  width: 100%;
  /* border-radius: 4px; */
  top: 0;
  transition: 200ms ease-in-out;
  opacity: 0.75;
  z-index: 1;
}
</style>